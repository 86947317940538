import { WidgetFactory } from 'nucleus-widgets';

/**
 * Calendar's guest widget factory that contains all widgets that can appear on the calendar website
 */
class GuestSideWidgetFactory extends WidgetFactory {
  constructor() {
    super([
      {
        metadata: {
          type: 'NucleusEmptyCell',
          name: '_widgetName_empty__resx',
          minCellSize: 1
        },
        // @ts-expect-error TS(7016): Could not find a declaration file for module 'nucl... Remove this comment to see the full error message
        creator: () => import('nucleus-widgets/lib/widgets/EmptyCell').then(m => m.EmptyCellWidget)
      },
      {
        metadata: {
          type: 'NucleusText',
          name: '_widgetName_text__resx',
          minCellSize: 1
        },
        // @ts-expect-error TS(7016): Could not find a declaration file for module 'nucl... Remove this comment to see the full error message
        creator: () => import('nucleus-widgets/lib/widgets/Text').then(m => m.TextWidget)
      },
      {
        metadata: {
          type: 'CalendarList',
          name: 'Editor_Title_EventList__resx',
          minCellSize: 4
        },
        creator: () => {
          return new Promise(resolve => {
            // @ts-expect-error TS(2580): Cannot find name 'require'. Do you need to install... Remove this comment to see the full error message
            require.ensure(['./CalendarWidget'], (require: $TSFixMe) => {
              resolve(require('./CalendarWidget').default);
            });
          });
        }
      },
      {
        metadata: {
          type: 'CventFooter',
          name: '_widgetName_footer__resx',
          minCellSize: 4
        },
        // @ts-expect-error TS(7016): Could not find a declaration file for module 'nucl... Remove this comment to see the full error message
        creator: () => import('nucleus-widgets/lib/widgets/Footer').then(m => m.FooterWidget)
      },
      {
        metadata: {
          type: 'NucleusImage',
          name: '_widgetName_image__resx',
          minCellSize: 1
        },
        creator: () => {
          return new Promise(resolve => {
            // @ts-expect-error TS(2580): Cannot find name 'require'. Do you need to install... Remove this comment to see the full error message
            require.ensure(['./ImageWidget'], (require: $TSFixMe) => {
              resolve(require('./ImageWidget').default);
            });
          });
        }
      },
      {
        metadata: {
          type: 'NucleusImageCarousel',
          name: 'NucleusWidget_WidgetName_ImageCarousel__resx',
          minCellSize: 2
        },
        // @ts-expect-error TS(7016): Could not find a declaration file for module 'nucl... Remove this comment to see the full error message
        creator: () => import('nucleus-widgets/lib/widgets/ImageCarousel').then(m => m.ImageCarouselWidget)
      }
    ]);
  }
}

export default GuestSideWidgetFactory;
