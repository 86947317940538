// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import { mapValues } from 'lodash';

import layoutItemMigrations from './layoutItems';

/**
 * Migrations for the overall "website" object of the calendar site editor.
 * These are migrations to apply default value(i.e listView) to view format
 * of existing calendars
 */
export default function migrateCalendarWidget(website: $TSFixMe) {
  return {
    ...website,
    calendarWebsiteVersion: 1,
    layoutItems: mapValues(website.layoutItems, layoutItemMigrations)
  };
}
